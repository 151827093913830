import React from "react";

import SponsorTile from "./sponsor-tile";

import { useChallengesData } from "../hooks/use-challenges-data";

import * as sharedStyles from "../styles/shared";
import * as styles from "./sponsors-list.styles";

const S = {
  ...sharedStyles,
  ...styles,
};

const SponsorsList = ({ title }) => {
  const challenges = useChallengesData();

  return (
    <S.Container css={S.clipBox}>
      {title && <S.SpListTitle as="h2">{title}</S.SpListTitle>}
      <S.SpList as="ul">
        {challenges
          .sort((a, b) => a.sponsor.name.localeCompare(b.sponsor.name))
          .map((challenge, idx) => (
            <S.SpLI as="li" key={idx}>
              <SponsorTile {...challenge} />
            </S.SpLI>
          ))}
      </S.SpList>
    </S.Container>
  );
};

export default SponsorsList;
