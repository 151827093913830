import styled from "@emotion/styled";
// import { css } from "@emotion/react";
import { rem } from "../styles/utils";
import { H1 } from "../styles/shared";

const gutter = 40;

export const SpListTitle = styled(H1)`
  margin-top: ${rem(80)};
  margin-bottom: ${rem(80 - gutter)};
  text-align: center;
`;

export const SpList = styled.div`
  margin: 0 0 ${rem(80)};
  padding: 0;

  @media (min-width: ${rem(650)}) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 ${rem(gutter * -1)} ${rem(100)};
  }
`;

export const SpLI = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: ${rem(gutter * 0.5)} auto;

  @media (min-width: ${rem(700)}) {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: ${rem(1050)}) {
    width: calc(100% / 3);
  }
`;
