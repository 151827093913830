import React from "react";
import ReactMarkdown from "react-markdown";

// import { Link as GatsbyLink } from "gatsby";
// import * as S from "./markdown-block.styles";

const MarkdownBlock = ({ content, ...props }) => (
  <ReactMarkdown
    children={content}
    unwrapDisallowed
    // components={{
    //   ul: UList,
    //   ol: OList,
    //   li: ListItem,
    //   p: P,
    //   blockquote: Blockquote,
    //   a: Link,
    // }}
    {...props}
  />
);

export default MarkdownBlock;
