import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import Article from "app/components/article";
import HeroBar, { heroBarField } from "app/components/hero-bar";
import IntroBlock from "app/components/intro-block";
import Layout from "app/components/layout";
import SEOMeta, { seoField } from "app/components/seo";
import SponsorsList from "app/components/sponsors-list";
import VideoHeader, { videoHeaderField } from "app/components/video-header";

import * as sharedStyles from "app/styles/shared";
import * as pageStyles from "app/components/page.styles";

const S = { ...sharedStyles, ...pageStyles };

const Sponsors = ({
  ctaBlock,
  html,
  intro,
  sponsors,
  sponsorIntro,
  title,
  videoHeader,
  ...props
}) => (
  <Layout>
    <SEOMeta title={title} {..._get(props, "seo")} />
    <article>
      <IntroBlock minHeight={220} title={title} intro={intro} />
      {!!videoHeader && <VideoHeader {...videoHeader} accent={false} />}
      {!!_get(sponsors, "intro") && (
        <S.Container>
          <S.PageIntroText as="p">{_get(sponsors, "intro")}</S.PageIntroText>
        </S.Container>
      )}
      <SponsorsList title={_get(sponsors, "title")} />
      <Article title={sponsorIntro} html={html} />
      <HeroBar {...ctaBlock} />
    </article>
  </Layout>
);

export default Sponsors;

export const sponsorsFragment = graphql`
  fragment SponsorsPage on ContentJson {
    ...SEO
    title
    intro
    sponsors {
      title
      intro
    }
    html: body
    sponsorIntro
    ...VideoHeader
    ...HeroBar
  }
`;

export const sponsporsFields = [
  seoField,
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  {
    label: "Intro",
    name: "intro",
    widget: "markdown",
  },
  {
    ...videoHeaderField,
    label: "Featured Sponsor Hero",
  },
  {
    label: "Featured Challenge",
    name: "featuredChallenge",
    multiple: false,
    widget: "relation",
    collection: "challenges",
    searchFields: ["title", "sdg_id"],
    valueField: "title",
    displayFields: ["title", "sdg_id"],
  },
  {
    label: "Sponsors Module",
    name: "sponsors",
    widget: "object",
    fields: [
      {
        label: "Title",
        name: "title",
        widget: "string",
      },
      {
        label: "Headline",
        name: "intro",
        widget: "string",
      },
    ],
  },
  {
    label: "Content",
    name: "body",
    widget: "markdown",
  },
  {
    label: "Sponsors Title",
    name: "sponsorIntro",
    widget: "string",
  },
  heroBarField,
];
