import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import Sponsors from "app/templates/sponsors";

const SponsorsPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentJson(template: { eq: "sponsors" }) {
        ...SponsorsPage
      }
    }
  `);

  return <Sponsors {..._get(data, "contentJson")} />;
};

export default SponsorsPage;
