import React from "react";
import { Link } from "gatsby";
import Image from "./image";

import * as S from "./sponsor-tile.styles";

const SponsorTile = ({
  title,
  sdg,
  url,
  sponsor: { fullLogo, name, link },
}) => (
  <S.SprsTile>
    {!link && fullLogo && (
      <S.SprsTileImage>
        <Image image={fullLogo} />
      </S.SprsTileImage>
    )}
    {link && fullLogo && (
      <a href={link} target="_blank" rel="noopener noreferrer nofollow">
        <S.SprsTileImage>
          <Image image={fullLogo} />
        </S.SprsTileImage>
      </a>
    )}
    <S.SprsContent>
      <S.SprsTileTitle as="h3">
        {!link && name}
        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer nofollow">
            {name}
          </a>
        )}
      </S.SprsTileTitle>
      <S.SprsTileText as={Link} to={url}>
        {title}, SDG {sdg.sdg_id}
      </S.SprsTileText>
    </S.SprsContent>
  </S.SprsTile>
);

SponsorTile.defaultProps = {
  sponsor: {},
};

// export default memo(SponsorTile);
export default SponsorTile;
