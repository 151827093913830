import styled from "@emotion/styled";
// import { css } from "@emotion/react";

import { fontSize, fontWeights, rem } from "../styles/utils";
// import { desktop } from "../styles/breakpoints";
import { flexCenterAll } from "../styles/shared";

export const SprsTile = styled.figure`
  ${flexCenterAll};
  flex-direction: column;
  justify-content: flex-start;
  width: ${rem(300)};
  height: ${rem(360)};
  padding: ${rem(30)} 0;
  border-radius: ${rem(26)};
  box-shadow: 0 0 ${rem(16)} 0 rgba(0, 0, 0, 0.16);
  margin: ${rem(20)} auto;
`;

export const SprsTileImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(300)};
  height: ${rem(120)};
  overflow: hidden;
`;

export const SprsContent = styled.div`
  position: relative;
  padding: 0 ${rem(20)};
  width: 100%;
  text-align: center;

  &::before {
    position: absolute;
    top: -1px;
    left: 50%;
    content: "";
    display: block;
    width: ${rem(200)};
    height: 1px;
    background-color: #eeeeee;
    transform: translate3d(-50%, 0, 0);
  }
`;

export const SprsTileTitle = styled.h3`
  ${fontSize(18, 24)};
  font-weight: ${fontWeights.black};
  margin-top: ${rem(30)};
  margin-bottom: ${rem(10)};
`;

export const SprsTileText = styled.p`
  margin: 0;
  ${fontSize(18, 28)};
  font-weight: ${fontWeights.book};
`;
