import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { desktop } from "../styles/breakpoints";
import { H1, Container, innerContainer, bodyTxt } from "../styles/shared";
import { fontWeights, fontSize, colors, rem } from "../styles/utils";

export const ABox = styled(Container)`
  ${innerContainer};
  padding-top: ${rem(25)};
  margin-bottom: ${rem(80)};

  @media (${desktop}) {
    margin-bottom: ${rem(100)};
  }
`;

export const ATitle = styled(H1)`
  margin: 0 auto ${rem(40)};
  text-align: center;
`;

export const ABody = styled.div`
  ${bodyTxt};

  > * {
    margin-top: 0;
    margin-bottom: ${rem(20)};
  }

  h3 {
    ${fontSize(20, 30)};
  }

  > h2,
  > h3,
  > h4 {
    font-weight: ${fontWeights.black};
    margin-top: ${rem(60)};
  }

  ul {
    list-style: none;
    margin: 0 0 ${rem(20)};
    padding: 0;

    li {
      position: relative;
      padding-left: ${rem(30)};
      margin-bottom: ${rem(20)};

      &::before {
        content: "";
        position: absolute;
        top: calc(1em - ${rem(10)});
        left: 0;
        display: block;
        width: ${rem(10)};
        height: ${rem(10)};
        border-radius: 100%;
        background-color: ${colors.black};
      }
    }
  }

  a {
    color: ${colors.purple};
    text-decoration: underline;

    &:active,
    &:hover,
    &:focus {
      color: ${colors.purple};
    }

    &:hover {
      text-decoration: none;
    }
  }
`;

export const aBubble = css`
  margin: ${rem(40)} auto;
  padding: 0;
  width: ${rem(64)};
  height: ${rem(64)};
`;
